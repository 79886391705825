.about {
  position: relative;
}

.about__panel {
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  max-width: 945px;
  height: 160px;
  margin: auto;
  box-shadow: 0 6px 21px rgba(0, 0, 0, 0.17);
  background-color: #fff;
}

@media screen and (max-width: 1199px) {
  .about__panel {
    top: -65px;
    height: 130px;
  }
}

@media screen and (max-width: 991px) {
  .about__panel {
    top: -15px;
  }
}

@media screen and (max-width: 575px) {
  .about__panel {
    display: none;
  }
}

.about__advantages {
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

.about__advantage {
  padding: 17px 0 17px 65px;
  color: #373737;
  font-size: 18px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 55px;
}

@media screen and (max-width: 767px) {
  .about__advantage {
    padding-left: 50px;
    font-size: 16px;
    background-size: 40px;
  }
}

.about__advantage--efficiency {
  flex-basis: 200px;
  background-image: url("./efficiency.png");
}

@media screen and (max-width: 767px) {
  .about__advantage--efficiency {
    flex-basis: 26%;
  }
}

.about__advantage--passage-of-time {
  flex-basis: 245px;
  background-image: url("./passage-of-time.png");
}

@media screen and (max-width: 767px) {
  .about__advantage--passage-of-time {
    flex-basis: 30%;
  }
}

.about__advantage--tick-inside-circle {
  flex-basis: 200px;
  background-image: url("./tick-inside-circle.png");
}

@media screen and (max-width: 767px) {
  .about__advantage--tick-inside-circle {
    flex-basis: 26%;
  }
}

.about__wrapper {
  max-width: 954px;
  margin: 0 auto;
  padding: 154px 20px 100px;
}

@media screen and (max-width: 575px) {
  .about__wrapper {
    padding: 80px 20px 60px;
  }
}

.about__content {
  text-align: center;
}

.about__title {
  margin-bottom: 36px;
}

.about__text {
  margin-bottom: 70px;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 479px) {
  .about__text {
    margin-bottom: 60px;
    font-size: 16px;
  }
}

.about__counts ul {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 575px) {
  .about__counts ul {
    flex-direction: column;
  }
}

.about__counts ul li {
  text-align: center;
}

@media screen and (max-width: 575px) {
  .about__counts ul li {
    margin-bottom: 40px;
  }
}

.about__counts ul li:first-child .about__count {
  background-size: 325%;
  background-position: 60% 0;
}

.about__counts ul li:nth-child(2) .about__count {
  background-size: 185%;
  background-position: 55% 0;
}

.about__counts ul li:nth-child(3) .about__count {
  background-size: 240%;
  background-position: 67% 20px;
}

@media screen and (max-width: 767px) {
  .about__counts ul li:nth-child(3) .about__count {
    background-position: 67% 17px;
  }
}

@media screen and (max-width: 575px) {
  .about__counts ul li:nth-child(3) .about__count {
    background-position: 67% 0;
  }
}

.about__desc {
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 575px) {
  .about__desc {
    font-size: 20px;
    line-height: 20px;
  }
}

@media screen and (max-width: 575px) {
  .about__desc br {
    display: none;
  }
}

.about__count {
  padding: 20px 0;
  font-weight: 900;
  color: #0086f0;
  font-size: 55px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: url("./number-decor.png");
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .about__count {
    padding: 10px 0;
    font-size: 40px;
  }
}

@media screen and (max-width: 575px) {
  .about__count {
    font-size: 50px;
    padding: 5px 0;
  }
}

@media screen and (max-width: 479px) {
  .about__count {
    font-size: 44px;
  }
}

