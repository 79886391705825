.popup {
  position: relative;
  width: auto;
  max-width: 420px;
  height: 280px;
  margin: 20px auto;
  padding: 40px 30px 38px;
  background: #fff;
}

.popup .mfp-close {
  display: none;
}

.popup__input {
  margin-bottom: 25px;
}

.popup__input input {
  width: 100%;
  padding: 14px 10px 14px 35px;
  font-family: "GothamPro", sans-serif;
  font-size: 16px;
  color: #171717;
  border: 1px solid #c2c2c2;
}

.popup__input input::placeholder {
  color: #8b8b8b;
  font-size: 16px;
}

.popup__input button {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  color: #fff;
  background-color: #e31851;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}

.popup__input button:hover {
  border-color: #e31851;
  color: #e31851;
  background-color: #fff;
}

.popup__input-name {
  background-image: url("./user-icon.png");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
}

.popup__input-phone {
  background-image: url("./phone-icon.png");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 12px;
}

