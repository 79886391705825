@import "~common";

.equipment {
  background-color: #ededed;

  &__wrapper {
    padding: 44px 0 42px;

    @include media($mobile-md) {
      padding-top: 80px; } }

  &__title {
    margin-bottom: 38px;
    text-align: center; }

  &__list {
    display: flex;
    justify-content: space-between;

    @include media($mobile-md) {
      flex-direction: column; } }

  &__item {
    flex-basis: 31.6%;
    text-align: center;

    @include media($mobile-md) {
      margin-bottom: 40px; } }

  &__img {
    margin-bottom: 20px;

    @include media($mobile-lg) {
      height: 130px; }

    @include media($mobile-md) {
      height: 270px; }

    @include media($mobile-sm) {
      height: 200px; }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__name {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: $font-bold;

    @include media($tablet) {
      font-size: 17px; }

    @include media($mobile-md) {
      margin-bottom: 15px;
      font-size: 23px; }

    @include media($mobile-xs) {
      font-size: 20px; } }

  &__text {

    @include media($tablet) {
      font-size: 15px;
      line-height: 20px; }

    @include media($mobile-md) {
      font-size: 17px; }

    @include media($mobile-xs) {
      font-size: 15px; }

    br {

      @include media($tablet) {
        display: none; } } } }
