@media screen and (max-width: 575px) {
  .cost .container {
    padding: 0 5px;
  }
}

.cost__wraper {
  padding: 77px 0 38px;
}

.cost__wraper .ui-tabs,
.cost__wraper .ui-tabs .ui-tabs-panel {
  padding: 0;
}

.cost__wraper .ui-widget-header {
  border: none;
  background: transparent;
}

.cost__wraper .ui-tabs .ui-tabs-nav {
  max-width: 974px;
  margin: 0 auto 45px;
}

.cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab {
  width: 33.333%;
  margin: 0;
  background: #fff;
  border-radius: 0;
  border: 1px solid #cbcbcb;
}

@media screen and (max-width: 575px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab {
    float: none;
    width: 100%;
    height: auto;
  }
}

.cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab.ui-tabs-active {
  background: #e31851;
  border: none;
}

.cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab.ui-tabs-active a {
  color: #fff;
}

.cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab:last-child .ui-tabs-anchor {
  padding-top: 25px;
}

@media screen and (max-width: 767px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab:last-child .ui-tabs-anchor {
    padding-top: 18px;
  }
}

@media screen and (max-width: 575px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab:last-child .ui-tabs-anchor {
    padding-top: 15px;
  }
}

.cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab .ui-tabs-anchor {
  width: 100%;
  height: 71px;
  padding-top: 14px;
  color: #171717;
  font-family: "GothamPro", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  outline: none;
}

@media screen and (max-width: 767px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab .ui-tabs-anchor {
    height: 55px;
    padding-top: 8px;
    font-size: 15px;
  }
}

@media screen and (max-width: 575px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab .ui-tabs-anchor {
    float: none;
    display: block;
    height: 45px;
    padding-top: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 479px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab .ui-tabs-anchor {
    height: 40px;
    font-size: 13px;
  }
}

@media screen and (max-width: 575px) {
  .cost__wraper .ui-tabs .ui-tabs-nav .ui-tabs-tab .ui-tabs-anchor br {
    display: none;
  }
}

.cost__wraper .ui-widget.ui-widget-content {
  border: none;
}

.cost__title {
  margin-bottom: 40px;
  text-align: center;
}

.cost__subtitle {
  margin-bottom: 35px;
  font-size: 22px;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .cost__subtitle {
    font-size: 18px;
  }
}

.cost__range {
  position: relative;
  max-width: 811px;
  margin: 0 auto 35px;
  padding-bottom: 15px;
}

@media screen and (max-width: 575px) {
  .cost__range {
    padding: 0 15px 15px;
  }
}

.cost__range .irs-slider {
  width: 27px;
  height: 27px;
  box-shadow: 0 0 10px 1px rgba(0, 114, 205, 0.49);
  border-width: 0;
  border-radius: 14px;
  background: #2da2ff;
}

@media screen and (max-width: 575px) {
  .cost__range .irs-slider {
    top: 28px;
    width: 20px;
    height: 20px;
  }
}

.cost__range .irs-slider:hover,
.cost__range .irs-slider.state_hover {
  background: #2da2ff;
}

.cost__range .irs-bar,
.cost__range .irs-bar-edge {
  background: transparent;
  border: none;
}

.cost__range .irs-line {
  background: #575757;
  border: none;
  border-radius: 0;
}

.cost__range .irs-grid {
  bottom: auto;
  top: -10px;
}

.cost__range .irs-grid span:nth-child(1),
.cost__range .irs-grid span:nth-child(2),
.cost__range .irs-grid span:nth-child(13),
.cost__range .irs-grid span:nth-child(14),
.cost__range .irs-grid span:nth-child(17),
.cost__range .irs-grid span:nth-child(18),
.cost__range .irs-grid span:nth-child(25),
.cost__range .irs-grid span:nth-child(26),
.cost__range .irs-grid span:nth-child(33),
.cost__range .irs-grid span:nth-child(34),
.cost__range .irs-grid span:nth-child(38),
.cost__range .irs-grid span:nth-child(37),
.cost__range .irs-grid span:nth-child(41),
.cost__range .irs-grid span:nth-child(42) {
  display: none;
}

.cost__range .irs-grid-text {
  font-family: "GothamPro", sans-serif;
  font-size: 18px;
  color: #171717;
  visibility: visible !important;
}

@media screen and (max-width: 767px) {
  .cost__range .irs-grid-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 575px) {
  .cost__range .irs-grid-text {
    font-size: 13px;
  }
}

.cost__range .irs-grid-text:after {
  content: '';
}

.cost__range .irs-grid-pol {
  top: 35px;
  background: #171717;
}

.cost__range .irs-grid-pol.small {
  display: none;
}

@media screen and (max-width: 575px) {
  .cost__range .js-grid-text-1 {
    left: 8% !important;
  }
}

@media screen and (max-width: 575px) {
  .cost__range .js-grid-text-2 {
    left: 23% !important;
  }
}

@media screen and (max-width: 360px) {
  .cost__range .js-grid-text-2 {
    left: 26% !important;
  }
}

.cost__sale {
  position: absolute;
  bottom: 0;
  color: #606060;
  font-family: "GothamPro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .cost__sale {
    bottom: 5px;
    font-size: 13px;
  }
}

.cost__sale span {
  color: #1d93f1;
}

.cost__sale--red span {
  color: #e80041;
}

.cost__sale--10 {
  left: 13%;
}

.cost__sale--12 {
  left: 32.5%;
}

.cost__sale--18 {
  left: 56.5%;
}

.cost__sale--23 {
  left: 83%;
}

.cost__more350 {
  position: absolute;
  top: -8px;
  right: 5%;
  font-family: "GothamPro", sans-serif;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .cost__more350 {
    top: -5px;
    font-size: 15px;
  }
}

@media screen and (max-width: 575px) {
  .cost__more350 {
    top: -3px;
    right: 2%;
    font-size: 12px;
  }
}

@media screen and (max-width: 479px) {
  .cost__more350 {
    display: none;
  }
}

.cost__more350--mobile {
  display: none;
}

@media screen and (max-width: 479px) {
  .cost__more350--mobile {
    display: block;
    right: 4%;
  }
}

