@import "~common";

.popup {
  position: relative;
  width: auto;
  max-width: 420px;
  height: 280px;
  margin: 20px auto;
  padding: 40px 30px 38px;
  background: #fff;

  .mfp-close {
    display: none; }

  &__input {
    margin-bottom: 25px;

    input {
      width: 100%;
      padding: 14px 10px 14px 35px;
      font-family: $font_main;
      font-size: 16px;
      color: #171717;
      border: 1px solid #c2c2c2;

      &::placeholder {
        color: #8b8b8b;
        font-size: 16px; } }

    button {
      width: 100%;
      padding: 12px 0;
      font-size: 16px;
      color: #fff;
      background-color: $color-button;
      border: 2px solid transparent;
      cursor: pointer;
      transition: border-color 0.3s, color 0.3s, background-color 0.3s;

      &:hover {
        border-color: $color-button;
        color: $color-button;
        background-color: #fff; } } }

  &__input-name {
    background-image: url('user-icon.png');
    background-repeat: no-repeat;
    background-position: left 12px center;
    background-size: 16px; }

  &__input-phone {
    background-image: url('phone-icon.png');
    background-repeat: no-repeat;
    background-position: left 12px center;
    background-size: 12px; } }
