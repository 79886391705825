@font-face {
  font-family: "GothamPro";
  font-weight: 400;
  font-style: normal;
  src: url("./GothamPro/GothamPro-Regular.eot");
  src: url("./GothamPro/GothamPro-Regular.eot") format("embedded-opentype"), url("./GothamPro/GothamPro-Regular.woff") format("woff"), url("./GothamPro/GothamPro-Regular.ttf") format("truetype"), url("./GothamPro/GothamPro-Regular.svg") format("svg");
}

@font-face {
  font-family: "GothamPro";
  font-weight: 500;
  font-style: normal;
  src: url("./GothamPro/GothamPro-Medium.eot");
  src: url("./GothamPro/GothamPro-Medium.eot") format("embedded-opentype"), url("./GothamPro/GothamPro-Medium.woff") format("woff"), url("./GothamPro/GothamPro-Medium.ttf") format("truetype"), url("./GothamPro/GothamPro-Medium.svg") format("svg");
}

@font-face {
  font-family: "GothamPro";
  font-weight: 700;
  font-style: normal;
  src: url("./GothamPro/GothamPro-Bold.eot");
  src: url("./GothamPro/GothamPro-Bold.eot") format("embedded-opentype"), url("./GothamPro/GothamPro-Bold.woff") format("woff"), url("./GothamPro/GothamPro-Bold.ttf") format("truetype"), url("./GothamPro/GothamPro-Bold.svg") format("svg");
}

@font-face {
  font-family: "GothamPro";
  font-weight: 900;
  font-style: normal;
  src: url("./GothamPro/GothamPro-Black.eot");
  src: url("./GothamPro/GothamPro-Black.eot") format("embedded-opentype"), url("./GothamPro/GothamPro-Black.woff") format("woff"), url("./GothamPro/GothamPro-Black.ttf") format("truetype"), url("./GothamPro/GothamPro-Black.svg") format("svg");
}

