@import "~common";

.cost {

  .container {

    @include media($mobile-md) {
      padding: 0 5px; } }

  &__wraper {
    padding: 77px 0 38px;

    .ui-tabs, .ui-tabs .ui-tabs-panel {
      padding: 0; }

    .ui-widget-header {
      border: none;
      background: transparent; }

    .ui-tabs .ui-tabs-nav {
      max-width: 974px;
      margin: 0 auto 45px;

      .ui-tabs-tab {
        width: 33.333%;
        margin: 0;
        background: #fff;
        border-radius: 0;
        border: 1px solid #cbcbcb;

        @include media($mobile-md) {
          float: none;
          width: 100%;
          height: auto; }

        &.ui-tabs-active {
          background: $color-button;
          border: none;

          a {
            color: #fff; } }

        &:last-child {

          .ui-tabs-anchor {
            padding-top: 25px;

            @include media($mobile-lg) {
              padding-top: 18px; }

            @include media($mobile-md) {
              padding-top: 15px; } } }

        .ui-tabs-anchor {
          width: 100%;
          height: 71px;
          padding-top: 14px;
          color: #171717;
          font-family: $font_main;
          font-size: 18px;
          font-weight: $font-medium;
          text-align: center;
          outline: none;

          @include media($mobile-lg) {
            height: 55px;
            padding-top: 8px;
            font-size: 15px; }

          @include media($mobile-md) {
            float: none;
            display: block;
            height: 45px;
            padding-top: 12px;
            text-align: center; }

          @include media($mobile-sm) {
            height: 40px;
            font-size: 13px; }

          br {

            @include media($mobile-md) {
              display: none; } } } } }

    .ui-widget.ui-widget-content {
      border: none; } }

  &__title {
    margin-bottom: 40px;
    text-align: center; }

  &__subtitle {
    margin-bottom: 35px;
    font-size: 22px;
    text-align: center;

    @include media($mobile-md) {
      font-size: 18px; } }

  &__range {
    position: relative;
    max-width: 811px;
    margin: 0 auto 35px;
    padding-bottom: 15px;

    @include media($mobile-md) {
      padding: 0 15px 15px; }

    .irs-slider {
      width: 27px;
      height: 27px;
      box-shadow: 0 0 10px 1px rgba(0, 114, 205, 0.49);
      border-width: 0;
      border-radius: 14px;
      background: #2da2ff;

      @include media($mobile-md) {
        top: 28px;
        width: 20px;
        height: 20px; }

      &:hover, &.state_hover {
        background: #2da2ff; } }

    .irs-bar, .irs-bar-edge {
      background: transparent;
      border: none; }

    .irs-line {
      background: #575757;
      border: none;
      border-radius: 0; }

    .irs-grid {
      bottom: auto;
      top: -10px;

      span {

        &:nth-child(1), &:nth-child(2), &:nth-child(13), &:nth-child(14),
        &:nth-child(17), &:nth-child(18), &:nth-child(25), &:nth-child(26),
        &:nth-child(33), &:nth-child(34), &:nth-child(38), &:nth-child(37),
        &:nth-child(41), &:nth-child(42) {
          display: none; } } }

    .irs-grid-text {
      font-family: $font_main;
      font-size: 18px;
      color: #171717;
      visibility: visible!important;

      @include media($mobile-lg) {
        font-size: 15px; }

      @include media($mobile-md) {
        font-size: 13px; }

      &:after {
        content: ''; } }

    .irs-grid-pol {
      top: 35px;
      background: #171717;

      &.small {
        display: none; } }

    .js-grid-text-1 {

      @include media($mobile-md) {
        left: 8%!important; } }

    .js-grid-text-2 {

      @include media($mobile-md) {
        left: 23%!important; }

      @include media($mobile-xs) {
        left: 26%!important; } } }

  &__sale {
    position: absolute;
    bottom: 0;
    color: #606060;
    font-family: $font_main;
    font-size: 16px;
    font-weight: $font-medium;
    text-align: center;

    @include media($mobile-lg) {
      bottom: 5px;
      font-size: 13px; }

    span {
      color: #1d93f1; }

    &--red {

      span {
        color: #e80041; } }

    &--10 {
      left: 13%; }

    &--12 {
      left: 32.5%; }

    &--18 {
      left: 56.5%; }

    &--23 {
      left: 83%; } }

  &__more350 {
    position: absolute;
    top: -8px;
    right: 5%;
    font-family: $font_main;
    font-size: 18px;

    @include media($mobile-lg) {
      top: -5px;
      font-size: 15px; }

    @include media($mobile-md) {
      top: -3px;
      right: 2%;
      font-size: 12px; }

    @include media($mobile-sm) {
      display: none; }

    &--mobile {
      display: none;

      @include media($mobile-sm) {
        display: block;
        right: 4%; } } } }
