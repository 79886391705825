@import "~common";

.varieties {
  background-color: #fff;

  &__wrapper {
    padding: 97px 0 80px;

    .ui-widget.ui-widget-content {
      border: none;
      padding: 0; }

    .ui-tabs .ui-tabs-nav {
      padding: 0;
      background: transparent;
      border: none; }

    .ui-tabs .ui-tabs-nav li {
      flex-basis: 22.5%;
      margin: 0;
      margin-bottom: 28px;
      border: none;
      background: transparent;

      @include media($tablet) {
        margin-bottom: 5px; }

      &.ui-tabs-active {

        @include media($tablet);
        margin-top: 0;
        margin-bottom: 5px;
        padding: 0; } }

    .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
      width: 100%;
      padding: 0;
      outline: none; }

    .ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
      color: $color-text-base; }

    .ui-tabs .ui-tabs-panel {
      padding: 0; }

    .ui-widget-content[aria-hidden="true"] {
      display: block !important;
      height: 0;
      overflow: hidden; }

    .ui-widget-content[aria-hidden="false"] {
      display: block !important;
      height: auto; } }

  &__title {
    margin-bottom: 38px;
    text-align: center; }

  &__list {
    display: flex;
    justify-content: space-between;

    @include media($tablet) {
      flex-direction: column; } }

  &__item {
    width: 100%;
    height: 182px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    overflow: hidden;
    cursor: pointer;
    transition: background-size 0.5s;

    @include media($tablet) {
      height: 60px; }

    &:hover {

      @include media($tablet) {
        background-size: 110%; }

      .varieties__button {
        margin-top: 2px;

        @include media($tablet) {
          margin-top: 0; } }

      .varieties__name {
        padding-top: 30px;

        @include media($tablet) {
          padding-top: 17px; } } }

    &--decor1 {
      background-image: url('decor1.png'); }

    &--decor2 {
      background-image: url('decor2.png'); }

    &--decor3 {
      background-image: url('decor3.png'); }

    &--decor4 {
      margin-right: 0;
      background-image: url('decor4.png'); }

    &--decor5 {
      background-image: url('decor5.png'); }

    &--decor6 {
      background-image: url('decor6.png'); }

    &--decor7 {
      background-image: url('decor7.png'); }

    &--decor8 {
      margin-right: 0;
      background-image: url('decor8.png'); } }

  &__name {
    height: calc(100% - 44px);
    padding-top: 55px;
    font-size: 20px;
    font-weight: $font-bold;
    text-align: center;
    transition: padding-top 0.3s;

    @include media($tablet) {
      height: auto;
      padding: 0;
      padding-top: 17px;
      font-size: 16px; }

    @include media(400px) {
      font-size: 15px; }

    @include media($mobile-xs) {
      font-size: 13px; }

    br {

      @include media($tablet) {
        display: none; } } }

  &__button {
    margin-top: 44px;
    padding: 13px 0;
    font-size: 16px;
    color: #fff;
    background-color: $color-button;
    text-align: center;
    transition: margin-top 0.3s;

    @include media($tablet) {
      display: none; } }

  &__banner {
    background-image: url('montazh.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

  &__banner-title {
    padding: 70px 20px;
    font-size: 30px;
    font-weight: $font-bold;
    line-height: 36px;
    text-align: center;

    @include media($mobile-md) {
      padding: 40px 20px;
      font-size: 25px; }

    @include media($mobile-sm) {
      padding: 60px 10px;
      font-size: 22px;
      line-height: 30px; }

    span {
      color: $color-button;
      font-weight: $font-weight-black; } } }
