@import "~common";

.feedback {
  background-image: url('feed-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &--decor {
    background-image: url('feed-decor-left.png'), url('feed-decor-right.png'), url('feed-bg.png');
    background-repeat: no-repeat;
    background-position: left top, right bottom, center;
    background-size: contain, contain, cover;

    @include media($desktop-lg) {
      background-size: 40%, 40%, cover; }

    .feedback__wrapper {
      max-width: 750px;

      @include media(800px) {
        max-width: 600px; } } }

  &__wrapper {
    max-width: 766px;
    margin: 0 auto;
    padding: 64px 20px 111px;
    text-align: center;

    @include media(800px) {
      max-width: 600px; }

    @include media($mobile-sm) {
      padding: 64px 20px 64px; } }

  &__title {
    margin-bottom: 15px; }

  &__text {
    margin-bottom: 60px; }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media(800px) {
      flex-direction: column; } }

  &__input {
    flex-basis: 30%;

    @include media(800px) {
      flex-basis: auto;
      margin-bottom: 10px; }

    &:last-child {

      @include media(800px) {
        margin-bottom: 0;
        margin-top: 20px; } }

    input {
      width: 100%;
      padding: 14px 10px 14px 35px;
      border: none;
      font-family: $font_main;
      font-size: 16px;
      color: #171717;

      &::placeholder {
        color: #8b8b8b;
        font-size: 16px; } }

    button {
      width: 225px;
      padding: 12px 0;
      font-size: 16px;
      color: #fff;
      background-color: $color-button;
      border: 2px solid transparent;
      cursor: pointer;
      transition: border-color 0.3s, color 0.3s, background-color 0.3s;

      &:hover {
        border-color: $color-button;
        color: $color-button;
        background-color: #fff; } } }

  &__input-name {
    background-image: url('../../../blocks/calculator/user-icon.png');
    background-repeat: no-repeat;
    background-position: left 12px center;
    background-size: 16px; }

  &__input-phone {
    background-image: url('../../../blocks/calculator/phone-icon.png');
    background-repeat: no-repeat;
    background-position: left 12px center;
    background-size: 12px; } }
