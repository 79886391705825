@import "~common";

.header {
  background-color: $color-background;

  @include media($tablet) {
    position: fixed;
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid #dcdcdc; }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 135px;

    @include media($tablet) {
      height: 80px; }

    @include media($mobile-md) {
      height: 70px; } }

  &__logo {
    display: block;
    width: 383px;
    height: 62px;
    z-index: 20;
    background-image: url('logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media($desktop) {
      width: 320px;
      height: 52px; }

    @include media($mobile-md) {
      width: 230px;
      height: 37px; }

    @include media($mobile-xs) {
      width: 210px;
      height: 34px; } }

  &__content {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;

    @include media($tablet) {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      z-index: 10;
      top: 0;
      right: 0;
      width: 320px;
      height: 100vh;
      padding: 70px 20px 50px;
      background-color: #fff;
      transform: translateX(100%);
      transition: transform 0.3s; }

    @include media($mobile-sm) {
      width: 100%; }

    &.open {

      @include media($tablet) {
        transform: translateX(0); } } }

  &__menu {

    a {
      display: block;
      text-decoration: none;
      color: $color-text-base;
      font-size: 16px;
      transition: color 0.3s;

      @include media($desktop) {
        font-size: 15px; }

      &:hover {
        color: $color-theme; } } }

  &__nav {
    margin-bottom: 15px;

    @include media($tablet) {
      margin-bottom: 55px; }

    ul {
      display: flex;
      justify-content: space-around;

      @include media($tablet) {
        display: block; }

      a {
        padding: 12px 27px;
        text-transform: uppercase;

        @include media($desktop) {
          padding: 12px 0; }

        @include media($tablet) {
          padding: 15px 20px;
          font-size: 17px;
          font-weight: $font-medium; } } } }

  &__contacts {
    display: flex;
    justify-content: space-around;

    @include media($tablet) {
      display: block; }

    a {

      @include media($desktop) {
        font-size: 13px;
        padding: 2px 0 2px 25px; }

      @include media($tablet) {
        font-size: 14px;
        margin-right: 0; } } }

  &__email {
    margin-right: 15px;
    padding-left: 25px;
    background-image: url('email-icon.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 17px;

    @include media($tablet) {
      margin-bottom: 10px; } }

  &__address {
    padding-left: 25px;
    background-image: url('location-icon.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 13px; }

  &__phone {
    display: inline-block;
    margin-bottom: 8px;
    color: #171717;
    font-size: 20px;
    font-weight: $font-medium;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: $color-theme; } }

  &__button {

    a {
      display: inline-block;
      padding: 11px 13px 11px 33px;
      font-family: $font_main;
      color: $color-text-base;
      font-size: 16px;
      font-weight: $font-medium;
      border: 2px solid $color-button;
      background-color: #fff;
      background-image: url('phone-icon.png');
      background-repeat: no-repeat;
      background-position: left 15px center;
      background-size: 11px;
      cursor: pointer;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: $color-button;
        color: #fff; } } }

  &__burger {
    display: none;
    position: relative;
    z-index: 20;
    width: 40px;
    height: 34px;
    margin-top: -8px;
    box-sizing: content-box;
    transform: rotate(0deg);
    transition: 0.3s ease-in-out;
    cursor: pointer;

    @include media($tablet) {
      display: block; }

    &.open {

      span {

        &:nth-child(1) {
          top: 16px;
          width: 0; }

        &:nth-child(2) {
          transform: rotate(45deg); }

        &:nth-child(3) {
          transform: rotate(-45deg); }

        &:nth-child(4) {
          top: 16px;
          width: 0; } } }

    span {
      position: absolute;
      display: block;
      right: 0;
      left: 0;
      width: 28px;
      height: 3px;
      margin: 0 auto;
      background: #000;
      border-radius: 9px;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;

      @include media($mobile_sm) {
        width: 24px; }

      &:nth-child(1) {
        top: 8px; }

      &:nth-child(2), &:nth-child(3) {
        top: 16px; }

      &:nth-child(4) {
        top: 24px; } } } }

