.feedback {
  background-image: url("./feed-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.feedback--decor {
  background-image: url("./feed-decor-left.png"), url("./feed-decor-right.png"), url("./feed-bg.png");
  background-repeat: no-repeat;
  background-position: left top, right bottom, center;
  background-size: contain, contain, cover;
}

@media screen and (max-width: 1350px) {
  .feedback--decor {
    background-size: 40%, 40%, cover;
  }
}

.feedback--decor .feedback__wrapper {
  max-width: 750px;
}

@media screen and (max-width: 800px) {
  .feedback--decor .feedback__wrapper {
    max-width: 600px;
  }
}

.feedback__wrapper {
  max-width: 766px;
  margin: 0 auto;
  padding: 64px 20px 111px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .feedback__wrapper {
    max-width: 600px;
  }
}

@media screen and (max-width: 479px) {
  .feedback__wrapper {
    padding: 64px 20px 64px;
  }
}

.feedback__title {
  margin-bottom: 15px;
}

.feedback__text {
  margin-bottom: 60px;
}

.feedback__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .feedback__field {
    flex-direction: column;
  }
}

.feedback__input {
  flex-basis: 30%;
}

@media screen and (max-width: 800px) {
  .feedback__input {
    flex-basis: auto;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  .feedback__input:last-child {
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.feedback__input input {
  width: 100%;
  padding: 14px 10px 14px 35px;
  border: none;
  font-family: "GothamPro", sans-serif;
  font-size: 16px;
  color: #171717;
}

.feedback__input input::placeholder {
  color: #8b8b8b;
  font-size: 16px;
}

.feedback__input button {
  width: 225px;
  padding: 12px 0;
  font-size: 16px;
  color: #fff;
  background-color: #e31851;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}

.feedback__input button:hover {
  border-color: #e31851;
  color: #e31851;
  background-color: #fff;
}

.feedback__input-name {
  background-image: url("./../../../blocks/calculator/user-icon.png");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
}

.feedback__input-phone {
  background-image: url("./../../../blocks/calculator/phone-icon.png");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 12px;
}

