@import "~common";

html {
 box-sizing: border-box;
 font-family: $font_main;
 font-size: $font-size-base;
 color: $color-text-base; }

*, *:before, *:after {
 box-sizing: inherit; }

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
 margin: 0;
 padding: 0;
 font-weight: normal; }

h1 {
 font-size: 40px;
 font-weight: $font-bold;
 line-height: 50px; }

h2 {
 font-size: 30px;
 font-weight: $font-bold;

 @include media($mobile-lg) {
  font-size: 26px; } }

ol, ul {
 list-style: none; }

img {
 max-width: 100%;
 height: auto; }
