.calculator {
  margin-bottom: 80px;
}

.calculator__title {
  margin-top: 36px;
  margin-bottom: 40px;
  text-align: center;
}

.calculator__body {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 1199px) {
  .calculator__body {
    flex-direction: column;
  }
}

.calculator__left {
  flex-basis: 653px;
  padding: 35px 35px 48px;
  background-color: #f3f3f3;
}

@media screen and (max-width: 1199px) {
  .calculator__left {
    flex-basis: auto;
    padding: 35px 95px 48px;
  }
}

@media screen and (max-width: 991px) {
  .calculator__left {
    padding: 35px 25px 48px;
  }
}

@media screen and (max-width: 479px) {
  .calculator__left {
    padding: 35px 15px 48px;
  }
}

.calculator__template {
  margin-bottom: 40px;
}

@media screen and (max-width: 479px) {
  .calculator__template {
    margin-bottom: 25px;
  }
}

.calculator__field {
  margin-bottom: 40px;
}

@media screen and (max-width: 479px) {
  .calculator__field {
    margin-bottom: 25px;
  }
}

.calculator__field:last-child {
  margin-bottom: 0;
}

.calculator__field label {
  margin-right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 479px) {
  .calculator__field label {
    margin: 0;
  }
}

.calculator__field .jq-checkbox {
  width: 19px;
  height: 19px;
  border-radius: 0;
  border: 1px solid #c6c6c6;
  background: #fff;
}

.calculator__field .jq-checkbox.checked .jq-checkbox__div {
  width: 14px;
  height: 8px;
  margin: 2px 0 0 2px;
  border-color: #0086f0;
}

.calculator__field .jq-radio {
  width: 20px;
  height: 20px;
  background: #fff;
}

.calculator__field .jq-radio.checked .jq-radio__div {
  width: 12px;
  height: 12px;
  background: #0086f0;
  box-shadow: none;
}

.calculator__field--eyelet,
.calculator__field--mounting {
  display: inline-block;
  vertical-align: middle;
}

.calculator__field--gluing {
  display: inline-block;
  vertical-align: middle;
  margin-right: 95px;
}

.calculator__field--eyelet {
  margin-right: 98px;
}

@media screen and (max-width: 1199px) {
  .calculator__field--eyelet {
    margin-right: 10px;
  }
}

.calculator__field--calculate {
  margin-top: 35px;
}

.calculator__field--calculate button {
  display: block;
  width: 517px;
  margin: 0 auto;
  padding: 15px 0;
  color: #fff;
  background-color: #e31851;
  border: 2px solid transparent;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}

@media screen and (max-width: 700px) {
  .calculator__field--calculate button {
    width: 100%;
  }
}

.calculator__field--calculate button:hover {
  color: #e31851;
  background-color: #fff;
  border-color: #e31851;
}

.calculator__field--calculate button[disabled] {
  opacity: 0.5;
  cursor: default;
}

.calculator__field--calculate button[disabled]:hover {
  color: #fff;
  background-color: #e31851;
  border-color: transparent;
}

.calculator__field--m {
  margin-right: 8px;
}

.calculator__field--size {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 479px) {
  .calculator__field--size {
    display: block;
  }
}

.calculator__field--size input {
  width: 157px;
  padding: 12px 5px 12px 15px;
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 16px;
  border: none;
}

@media screen and (max-width: 991px) {
  .calculator__field--size input {
    width: 130px;
  }
}

@media screen and (max-width: 700px) {
  .calculator__field--size input {
    width: 100px;
  }
}

@media screen and (max-width: 575px) {
  .calculator__field--size input {
    width: 70px;
  }
}

@media screen and (max-width: 479px) {
  .calculator__field--size input {
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
  }
}

.calculator__field--size .jq-selectbox {
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .calculator__field--size .jq-selectbox {
    z-index: 5;
  }
}

.calculator__field--size .jq-selectbox.focused .jq-selectbox__select {
  border: none;
  box-shadow: none;
}

.calculator__field--size .jq-selectbox__select {
  padding: 0 35px 0 5px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
}

.calculator__field--size .jq-selectbox__select:hover {
  background: transparent;
  box-shadow: none;
}

.calculator__field--size .jq-selectbox__trigger {
  border: none;
}

.calculator__field--size .jq-selectbox__select-text {
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 18px;
}

.calculator__field--size .jq-selectbox__dropdown {
  left: 7px;
  width: 100%;
  margin-top: 5px;
  box-shadow: 0 5px 17px 1px rgba(0, 0, 0, 0.11);
  border: none;
  border-radius: 0;
}

.calculator__field--size .jq-selectbox__dropdown ul {
  padding: 10px 0;
}

.calculator__field--size .jq-selectbox__dropdown ul li {
  padding: 5px 12px;
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 16px;
}

@media screen and (max-width: 479px) {
  .calculator__field--size .jq-selectbox__dropdown ul li {
    font-size: 14px;
  }
}

.calculator__field--size .jq-selectbox__dropdown ul li:hover {
  background-color: #0086f0;
  color: #fff;
}

.calculator__field--material,
.calculator__field--resolution,
.calculator__field--type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .calculator__field--material,
  .calculator__field--resolution,
  .calculator__field--type {
    flex-direction: column;
    align-items: flex-start;
  }
}

.calculator__field--material .jq-selectbox,
.calculator__field--resolution .jq-selectbox,
.calculator__field--type .jq-selectbox {
  width: 340px;
}

@media screen and (max-width: 700px) {
  .calculator__field--material .jq-selectbox,
  .calculator__field--resolution .jq-selectbox,
  .calculator__field--type .jq-selectbox {
    width: 100%;
    margin-top: 15px;
    z-index: 5;
  }
}

.calculator__field--material .jq-selectbox.focused .jq-selectbox__select,
.calculator__field--resolution .jq-selectbox.focused .jq-selectbox__select,
.calculator__field--type .jq-selectbox.focused .jq-selectbox__select {
  border: none;
}

.calculator__field--material .jq-selectbox .jq-selectbox__select,
.calculator__field--resolution .jq-selectbox .jq-selectbox__select,
.calculator__field--type .jq-selectbox .jq-selectbox__select {
  padding: 5px 45px 5px 10px;
  border: none;
  box-shadow: none;
  background: #fff;
  border-radius: 0;
  box-sizing: content-box;
}

.calculator__field--material .jq-selectbox .jq-selectbox__select:hover,
.calculator__field--resolution .jq-selectbox .jq-selectbox__select:hover,
.calculator__field--type .jq-selectbox .jq-selectbox__select:hover {
  background: #fff;
}

.calculator__field--material .jq-selectbox .jq-selectbox__trigger,
.calculator__field--resolution .jq-selectbox .jq-selectbox__trigger,
.calculator__field--type .jq-selectbox .jq-selectbox__trigger {
  border: none;
}

.calculator__field--material .jq-selectbox .jq-selectbox__select-text,
.calculator__field--resolution .jq-selectbox .jq-selectbox__select-text,
.calculator__field--type .jq-selectbox .jq-selectbox__select-text {
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 16px;
}

@media screen and (max-width: 479px) {
  .calculator__field--material .jq-selectbox .jq-selectbox__select-text,
  .calculator__field--resolution .jq-selectbox .jq-selectbox__select-text,
  .calculator__field--type .jq-selectbox .jq-selectbox__select-text {
    font-size: 14px;
  }
}

.calculator__field--material .jq-selectbox .jq-selectbox__trigger-arrow,
.calculator__field--resolution .jq-selectbox .jq-selectbox__trigger-arrow,
.calculator__field--type .jq-selectbox .jq-selectbox__trigger-arrow {
  top: 20px;
}

.calculator__field--material .jq-selectbox .jq-selectbox__dropdown,
.calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown,
.calculator__field--type .jq-selectbox .jq-selectbox__dropdown {
  bottom: auto !important;
  width: 100%;
  margin-top: 5px;
  box-shadow: 0 5px 17px 1px rgba(0, 0, 0, 0.11);
  border: none;
  border-radius: 0;
}

@media screen and (max-width: 575px) {
  .calculator__field--material .jq-selectbox .jq-selectbox__dropdown,
  .calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown,
  .calculator__field--type .jq-selectbox .jq-selectbox__dropdown {
    width: auto;
  }
}

.calculator__field--material .jq-selectbox .jq-selectbox__dropdown ul,
.calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown ul,
.calculator__field--type .jq-selectbox .jq-selectbox__dropdown ul {
  padding: 15px 0;
}

.calculator__field--material .jq-selectbox .jq-selectbox__dropdown ul li,
.calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown ul li,
.calculator__field--type .jq-selectbox .jq-selectbox__dropdown ul li {
  padding: 5px 28px;
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 16px;
}

@media screen and (max-width: 575px) {
  .calculator__field--material .jq-selectbox .jq-selectbox__dropdown ul li,
  .calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown ul li,
  .calculator__field--type .jq-selectbox .jq-selectbox__dropdown ul li {
    padding: 5px;
    font-size: 15px;
  }
}

@media screen and (max-width: 479px) {
  .calculator__field--material .jq-selectbox .jq-selectbox__dropdown ul li,
  .calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown ul li,
  .calculator__field--type .jq-selectbox .jq-selectbox__dropdown ul li {
    font-size: 14px;
  }
}

.calculator__field--material .jq-selectbox .jq-selectbox__dropdown ul li:hover,
.calculator__field--resolution .jq-selectbox .jq-selectbox__dropdown ul li:hover,
.calculator__field--type .jq-selectbox .jq-selectbox__dropdown ul li:hover {
  background-color: #0086f0;
  color: #fff;
}

.calculator__field--luv input {
  width: 110px;
  padding: 12px 5px 12px 15px;
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 16px;
  border: none;
}

@media screen and (max-width: 700px) {
  .calculator__field--luv input {
    width: 100px;
  }
}

@media screen and (max-width: 479px) {
  .calculator__field--luv input {
    width: 94%;
    margin-top: 15px;
    z-index: 5;
  }
}

.calculator__field--luv.active {
  display: block;
}

.calculator__field--luv span {
  margin-left: 5px;
}

.calculator__step {
  display: none;
}

.calculator__right {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: calc(100% - 653px);
  padding: 30px 50px 48px;
}

@media screen and (max-width: 1199px) {
  .calculator__right {
    align-items: center;
    flex-direction: row;
    width: auto;
  }
}

@media screen and (max-width: 575px) {
  .calculator__right {
    padding: 30px 0 48px;
  }
}

.calculator__right.active {
  display: flex;
}

@media screen and (max-width: 1120px) {
  .calculator__right.active {
    display: block;
  }
}

@media screen and (max-width: 1199px) {
  .calculator__right ul {
    margin-bottom: 20px;
  }
}

.calculator__right ul li {
  margin-bottom: 15px;
  padding-left: 38px;
  background-image: url("./tick-red.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 22px;
  font-weight: 500;
}

.calculator__right ul li.hide {
  display: none;
}

.calculator__sum {
  max-width: 340px;
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .calculator__sum {
    max-width: none;
  }
}

@media screen and (max-width: 479px) {
  .calculator__sum {
    margin-bottom: 15px;
  }
}

.calculator__sum-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
}

.calculator__sum-count {
  color: #0086f0;
  font-size: 50px;
  font-weight: 700;
}

@media screen and (max-width: 479px) {
  .calculator__sum-count {
    font-size: 42px;
  }
}

@media screen and (max-width: 360px) {
  .calculator__sum-count {
    font-size: 37px;
  }
}

.calculator__message {
  display: none;
  max-width: 340px;
  text-align: center;
}

.calculator__message.active {
  display: block;
}

.calculator__help {
  font-size: 11px;
  color: #6d6d6d;
}

.calculator__in {
  height: 80px;
  padding-top: 35px;
}

@media screen and (max-width: 700px) {
  .calculator__in {
    margin-bottom: 10px;
  }
}

.calculator__in input {
  width: 100%;
  padding: 14px 10px 14px 35px;
  border: none;
  font-family: "GothamPro", sans-serif;
  font-size: 16px;
  color: #171717;
  outline: none;
}

.calculator__in input::placeholder {
  color: #8b8b8b;
  font-size: 16px;
}

.calculator__in button {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  color: #fff;
  background-color: #e31851;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}

.calculator__in button:hover {
  border-color: #e31851;
  color: #e31851;
  background-color: #fff;
}

.calculator__in-text {
  display: none;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  color: #0086f0;
}

.calculator__input-name {
  background-image: url("./user-icon.png");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
}

.calculator__input-phone {
  background-image: url("./phone-icon.png");
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 12px;
}

.calculator__user {
  margin-bottom: 20px;
  padding: 20px 35px;
  background-color: #f3f3f3;
}

@media screen and (max-width: 479px) {
  .calculator__user {
    padding: 20px 15px;
  }
}

.calculator__user .calculator__in {
  height: auto;
  padding-top: 0;
}

.calculator__user-title {
  margin-bottom: 20px;
  color: #0086f0;
  text-align: center;
  font-weight: 500;
}

.calculator__user-content {
  display: flex;
  justify-content: space-between;
  max-width: 495px;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .calculator__user-content {
    flex-direction: column;
  }
}

.calculator__sum-t {
  display: none;
}

.calculator__sum-text {
  display: none;
  font-size: 11px;
  color: #e31851;
  text-align: center;
}

.calculator__gluing-side {
  display: none;
}

.calc .calculator__user {
  display: none;
}

