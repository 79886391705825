.table {
  border: 1px solid #d3d3d3;
  font-family: "GothamPro", sans-serif;
}

.table--widescreen .table__col--big {
  width: 51.4%;
  padding: 12px 30px;
}

@media screen and (max-width: 991px) {
  .table--widescreen .table__col--big {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--widescreen .table__col--big {
    width: 54%;
  }
}

@media screen and (max-width: 767px) {
  .table--widescreen .table__col--big {
    padding: 10px;
  }
}

@media screen and (max-width: 479px) {
  .table--widescreen .table__col--big {
    padding: 5px;
  }
}

.table--widescreen .table__col--middle {
  width: 23.1%;
  padding: 12px 34px;
}

@media screen and (max-width: 991px) {
  .table--widescreen .table__col--middle {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--widescreen .table__col--middle {
    width: 23%;
  }
}

@media screen and (max-width: 767px) {
  .table--widescreen .table__col--middle {
    padding: 10px;
  }
}

@media screen and (max-width: 479px) {
  .table--widescreen .table__col--middle {
    padding: 5px;
  }
}

.table--widescreen .table__col--small {
  width: 25.5%;
  padding: 12px 34px;
}

@media screen and (max-width: 991px) {
  .table--widescreen .table__col--small {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--widescreen .table__col--small {
    width: 23%;
  }
}

@media screen and (max-width: 767px) {
  .table--widescreen .table__col--small {
    padding: 10px;
  }
}

@media screen and (max-width: 479px) {
  .table--widescreen .table__col--small {
    padding: 5px;
  }
}

.table--interior .table__col--big {
  width: 60%;
  padding: 12px 30px;
}

@media screen and (max-width: 991px) {
  .table--interior .table__col--big {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--interior .table__col--big {
    width: 70%;
  }
}

@media screen and (max-width: 479px) {
  .table--interior .table__col--big {
    padding: 5px;
  }
}

.table--interior .table__col--middle {
  width: 40%;
  padding: 12px 34px;
}

@media screen and (max-width: 991px) {
  .table--interior .table__col--middle {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--interior .table__col--middle {
    width: 30%;
  }
}

@media screen and (max-width: 479px) {
  .table--interior .table__col--middle {
    padding: 5px;
  }
}

.table--services .table__col--big {
  width: 60%;
  padding: 12px 30px;
}

@media screen and (max-width: 991px) {
  .table--services .table__col--big {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--services .table__col--big {
    width: 70%;
  }
}

@media screen and (max-width: 479px) {
  .table--services .table__col--big {
    padding: 5px;
  }
}

.table--services .table__col--middle {
  width: 40%;
  padding: 12px 34px;
}

@media screen and (max-width: 991px) {
  .table--services .table__col--middle {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 767px) {
  .table--services .table__col--middle {
    width: 30%;
  }
}

@media screen and (max-width: 479px) {
  .table--services .table__col--middle {
    padding: 5px;
  }
}

.table__header {
  background-color: #f3f3f3;
}

.table__header .table__col {
  font-size: 20px;
  font-weight: 500;
  padding-top: 14px;
  padding-bottom: 14px;
}

@media screen and (max-width: 991px) {
  .table__header .table__col {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .table__header .table__col {
    font-size: 15px;
  }
}

@media screen and (max-width: 479px) {
  .table__header .table__col {
    font-size: 11px;
  }
}

@media screen and (max-width: 360px) {
  .table__header .table__col {
    font-size: 10px;
  }
}

.table__row {
  border-bottom: 1px solid #d3d3d3;
}

.table__row:after {
  content: '';
  display: table;
  clear: both;
}

@media screen and (max-width: 767px) {
  .table__row {
    display: flex;
  }
}

.table__row:last-child {
  border-bottom: none;
}

.table__col {
  float: left;
  border-right: 1px solid #d3d3d3;
}

@media screen and (max-width: 991px) {
  .table__col {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .table__col {
    float: none;
  }
}

@media screen and (max-width: 479px) {
  .table__col {
    font-size: 13px;
  }
}

@media screen and (max-width: 360px) {
  .table__col {
    font-size: 12px;
  }
}

.table__col:last-child {
  border-right: none;
}

