.varieties-more {
  flex-basis: 100%;
  padding: 46px 108px 52px 26px;
  margin-bottom: 28px;
  background-color: #e9e9e9;
}

@media screen and (max-width: 1350px) {
  .varieties-more {
    height: auto;
    padding-right: 26px;
  }
}

@media screen and (max-width: 991px) {
  .varieties-more {
    margin-bottom: 5px;
  }
}

.varieties-more__slider-wrapper {
  display: inline-block;
  vertical-align: top;
  margin-left: 45px;
}

@media screen and (max-width: 1070px) {
  .varieties-more__slider-wrapper {
    display: block;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.varieties-more__slider {
  width: 292px;
}

@media screen and (max-width: 1070px) {
  .varieties-more__slider {
    margin: 0 auto;
  }
}

@media screen and (max-width: 479px) {
  .varieties-more__slider {
    width: 100%;
  }
}

.varieties-more__slider .slick-prev {
  left: -45px;
  background: url("./left-arrow.png");
}

.varieties-more__slider .slick-prev:hover,
.varieties-more__slider .slick-prev:focus {
  background: url("./left-arrow.png");
}

.varieties-more__slider .slick-next {
  right: -45px;
  background: url("./right-arrow.png");
}

.varieties-more__slider .slick-next:hover,
.varieties-more__slider .slick-next:focus {
  background: url("./right-arrow.png");
}

.varieties-more__slider .slick-arrow {
  width: 24px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.varieties-more__slider .slick-arrow:before {
  content: none;
}

.varieties-more__photo {
  width: 100%;
  height: 173px;
}

@media screen and (max-width: 479px) {
  .varieties-more__photo {
    height: 185px;
  }
}

@media screen and (max-width: 360px) {
  .varieties-more__photo {
    height: 150px;
  }
}

.varieties-more__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.varieties-more__content {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 415px);
  padding-left: 85px;
}

@media screen and (max-width: 1350px) {
  .varieties-more__content {
    width: calc(100% - 345px);
  }
}

@media screen and (max-width: 1070px) {
  .varieties-more__content {
    display: block;
    width: 100%;
    padding-left: 0;
  }
}

.varieties-more__title {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 700;
}

@media screen and (max-width: 479px) {
  .varieties-more__title {
    font-size: 20px;
  }
}

.varieties-more__text {
  margin-bottom: 30px;
  font-size: 16px;
}

@media screen and (max-width: 479px) {
  .varieties-more__text {
    font-size: 15px;
    line-height: 20px;
  }
}

.varieties-more__questions {
  display: inline-block;
  vertical-align: middle;
  margin-right: 36px;
  padding: 12px 28px;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #2d2d2d;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

@media screen and (max-width: 575px) {
  .varieties-more__questions {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}

.varieties-more__questions:hover {
  background-color: #2d2d2d;
  color: #fff;
}

.varieties-more__costing {
  display: inline-block;
  vertical-align: middle;
  padding: 12px 28px;
  color: #fff !important;
  background-color: #e31851;
  font-size: 16px;
  border: 2px solid transparent;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

@media screen and (max-width: 575px) {
  .varieties-more__costing {
    display: block;
    text-align: center;
  }
}

.varieties-more__costing:hover {
  background-color: #fff;
  color: #e31851 !important;
  border-color: #e31851;
}

