.footer {
  display: block;
  background-color: #3c3c3c;
}

.footer__wrapper {
  padding: 80px 0 60px;
}

.footer__map {
  display: inline-block;
  vertical-align: top;
  margin-right: 100px;
}

@media screen and (max-width: 1199px) {
  .footer__map {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.footer__map iframe {
  width: 555px;
  height: 257px;
}

@media screen and (max-width: 1199px) {
  .footer__map iframe {
    width: 100%;
    height: 350px;
  }
}

.footer__contacts {
  display: inline-block;
  vertical-align: top;
}

@media screen and (max-width: 1199px) {
  .footer__contacts {
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 1199px) {
  .footer__contacts ul {
    display: inline-block;
  }
}

@media screen and (max-width: 575px) {
  .footer__contacts ul {
    text-align: left;
  }
}

.footer__contacts li {
  margin-bottom: 18px;
}

.footer__contacts a {
  padding: 5px 0 5px 28px;
  text-decoration: none;
  color: #fff;
  background-repeat: no-repeat;
  transition: color 0.3s;
}

.footer__contacts a:hover {
  color: #0086f0;
}

.footer__contacts-title {
  margin-bottom: 30px;
  font-weight: 500;
  color: #fff;
}

.footer__contacts-phone {
  background-image: url("./phone-icon.png");
  background-size: 14px;
  background-position: left 3px center;
}

.footer__contacts-email {
  background-image: url("./email-icon.png");
  background-size: 20px;
  background-position: left center;
}

.footer__contacts-address {
  background-image: url("./mark-icon.png");
  background-size: 14px;
  background-position: left 3px center;
}

.footer__times {
  padding-left: 28px;
  color: #fff;
  line-height: 25px;
  background-image: url("./time-icon.png");
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left top;
}

.footer__copyright {
  padding: 23px 0;
  border-top: 1px solid #565656;
  color: #676767;
  font-size: 14px;
  text-align: center;
}

