@import "~common";

.about {
  position: relative;

  &__panel {
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    max-width: 945px;
    height: 160px;
    margin: auto;
    box-shadow: 0 6px 21px rgba(0, 0, 0, 0.17);
    background-color: #fff;

    @include media($desktop) {
      top: -65px;
      height: 130px; }

    @include media($tablet) {
      top: -15px; }

    @include media($mobile-md) {
      display: none; } }

  &__advantages {
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center; }

  &__advantage {
    padding: 17px 0 17px 65px;
    color: #373737;
    font-size: 18px;
    font-weight: $font-medium;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 55px;

    @include media($mobile-lg) {
      padding-left: 50px;
      font-size: 16px;
      background-size: 40px; }

    &--efficiency {
      flex-basis: 200px;
      background-image: url('efficiency.png');

      @include media($mobile-lg) {
        flex-basis: 26%; } }

    &--passage-of-time {
      flex-basis: 245px;
      background-image: url('passage-of-time.png');

      @include media($mobile-lg) {
        flex-basis: 30%; } }

    &--tick-inside-circle {
      flex-basis: 200px;
      background-image: url('tick-inside-circle.png');

      @include media($mobile-lg) {
        flex-basis: 26%; } } }

  &__wrapper {
    max-width: 954px;
    margin: 0 auto;
    padding: 154px 20px 100px;

    @include media($mobile-md) {
      padding: 80px 20px 60px; } }

  &__content {
    text-align: center; }

  &__title {
    margin-bottom: 36px; }

  &__text {
    margin-bottom: 70px;
    font-size: 18px;
    line-height: 24px;

    @include media($mobile-sm) {
      margin-bottom: 60px;
      font-size: 16px; } }

  &__counts {

    ul {
      display: flex;
      justify-content: space-around;

      @include media($mobile-md) {
        flex-direction: column; }

      li {
        text-align: center;

        @include media($mobile-md) {
          margin-bottom: 40px; }

        &:first-child {

          .about__count {
            background-size: 325%;
            background-position: 60% 0; } }

        &:nth-child(2) {

          .about__count {
            background-size: 185%;
            background-position: 55% 0; } }

        &:nth-child(3) {

          .about__count {
            background-size: 240%;
            background-position: 67% 20px;

            @include media($mobile-lg) {
              background-position: 67% 17px; }

            @include media($mobile-md) {
              background-position: 67% 0; } } } } } }

  &__desc {
    font-weight: $font-medium;
    line-height: 24px;

    @include media($mobile-md) {
      font-size: 20px;
      line-height: 20px; }

    br {

      @include media($mobile-md) {
        display: none; } } }

  &__count {
    padding: 20px 0;
    font-weight: $font-weight-black;
    color: $color-theme;
    font-size: 55px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-image: url('number-decor.png');
    background-repeat: no-repeat;

    @include media($mobile-lg) {
      padding: 10px 0;
      font-size: 40px; }

    @include media($mobile-md) {
      font-size: 50px;
      padding: 5px 0; }

    @include media($mobile-sm) {
      font-size: 44px; } } }
