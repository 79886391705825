.container {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (max-width: 991px) {
  .content {
    padding-top: 80px;
  }
}

@media screen and (max-width: 575px) {
  .content {
    padding-top: 70px;
  }
}

