.equipment {
  background-color: #ededed;
}

.equipment__wrapper {
  padding: 44px 0 42px;
}

@media screen and (max-width: 575px) {
  .equipment__wrapper {
    padding-top: 80px;
  }
}

.equipment__title {
  margin-bottom: 38px;
  text-align: center;
}

.equipment__list {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 575px) {
  .equipment__list {
    flex-direction: column;
  }
}

.equipment__item {
  flex-basis: 31.6%;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .equipment__item {
    margin-bottom: 40px;
  }
}

.equipment__img {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .equipment__img {
    height: 130px;
  }
}

@media screen and (max-width: 575px) {
  .equipment__img {
    height: 270px;
  }
}

@media screen and (max-width: 479px) {
  .equipment__img {
    height: 200px;
  }
}

.equipment__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.equipment__name {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .equipment__name {
    font-size: 17px;
  }
}

@media screen and (max-width: 575px) {
  .equipment__name {
    margin-bottom: 15px;
    font-size: 23px;
  }
}

@media screen and (max-width: 360px) {
  .equipment__name {
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  .equipment__text {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 575px) {
  .equipment__text {
    font-size: 17px;
  }
}

@media screen and (max-width: 360px) {
  .equipment__text {
    font-size: 15px;
  }
}

@media screen and (max-width: 991px) {
  .equipment__text br {
    display: none;
  }
}

