@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both; } }

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype'), font-url('#{$file-path}.svg##{$font-family}') format('svg'); }
    @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg'); } } }

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color; }

  &:-moz-placeholder {
    color: $color; }

  &::-moz-placeholder {
    color: $color; }

  &:-ms-input-placholder {
    color: $color; }

  &::-ms-input-placeholder {
    color: $color; } }

@mixin media($resolution) {
  @media screen and (max-width: $resolution) {
    @content; } }

@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap; }

@mixin link-custom {
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 50%;
    width: 0;
    height: 1px;
    background-color: $color_main;
    transition: width 0.3s; }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 1px;
    background-color: $color_main;
    transition: width 0.3s; }

  &:hover {
    &::before,
    &::after {
      width: 50%; } } }

