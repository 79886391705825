.header {
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  .header {
    position: fixed;
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid #dcdcdc;
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 135px;
}

@media screen and (max-width: 991px) {
  .header__wrapper {
    height: 80px;
  }
}

@media screen and (max-width: 575px) {
  .header__wrapper {
    height: 70px;
  }
}

.header__logo {
  display: block;
  width: 383px;
  height: 62px;
  z-index: 20;
  background-image: url("./logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 1199px) {
  .header__logo {
    width: 320px;
    height: 52px;
  }
}

@media screen and (max-width: 575px) {
  .header__logo {
    width: 230px;
    height: 37px;
  }
}

@media screen and (max-width: 360px) {
  .header__logo {
    width: 210px;
    height: 34px;
  }
}

.header__content {
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .header__content {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    top: 0;
    right: 0;
    width: 320px;
    height: 100vh;
    padding: 70px 20px 50px;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 0.3s;
  }
}

@media screen and (max-width: 479px) {
  .header__content {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .header__content.open {
    transform: translateX(0);
  }
}

.header__menu a {
  display: block;
  text-decoration: none;
  color: #171717;
  font-size: 16px;
  transition: color 0.3s;
}

@media screen and (max-width: 1199px) {
  .header__menu a {
    font-size: 15px;
  }
}

.header__menu a:hover {
  color: #0086f0;
}

.header__nav {
  margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .header__nav {
    margin-bottom: 55px;
  }
}

.header__nav ul {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .header__nav ul {
    display: block;
  }
}

.header__nav ul a {
  padding: 12px 27px;
  text-transform: uppercase;
}

@media screen and (max-width: 1199px) {
  .header__nav ul a {
    padding: 12px 0;
  }
}

@media screen and (max-width: 991px) {
  .header__nav ul a {
    padding: 15px 20px;
    font-size: 17px;
    font-weight: 500;
  }
}

.header__contacts {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 991px) {
  .header__contacts {
    display: block;
  }
}

@media screen and (max-width: 1199px) {
  .header__contacts a {
    font-size: 13px;
    padding: 2px 0 2px 25px;
  }
}

@media screen and (max-width: 991px) {
  .header__contacts a {
    font-size: 14px;
    margin-right: 0;
  }
}

.header__email {
  margin-right: 15px;
  padding-left: 25px;
  background-image: url("./email-icon.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 17px;
}

@media screen and (max-width: 991px) {
  .header__email {
    margin-bottom: 10px;
  }
}

.header__address {
  padding-left: 25px;
  background-image: url("./location-icon.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 13px;
}

.header__phone {
  display: inline-block;
  margin-bottom: 8px;
  color: #171717;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s;
}

.header__phone:hover {
  color: #0086f0;
}

.header__button a {
  display: inline-block;
  padding: 11px 13px 11px 33px;
  font-family: "GothamPro", sans-serif;
  color: #171717;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #e31851;
  background-color: #fff;
  background-image: url("./phone-icon.png");
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: 11px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.header__button a:hover {
  background-color: #e31851;
  color: #fff;
}

.header__burger {
  display: none;
  position: relative;
  z-index: 20;
  width: 40px;
  height: 34px;
  margin-top: -8px;
  box-sizing: content-box;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .header__burger {
    display: block;
  }
}

.header__burger.open span:nth-child(1) {
  top: 16px;
  width: 0;
}

.header__burger.open span:nth-child(2) {
  transform: rotate(45deg);
}

.header__burger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.header__burger.open span:nth-child(4) {
  top: 16px;
  width: 0;
}

.header__burger span {
  position: absolute;
  display: block;
  right: 0;
  left: 0;
  width: 28px;
  height: 3px;
  margin: 0 auto;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 479px) {
  .header__burger span {
    width: 24px;
  }
}

.header__burger span:nth-child(1) {
  top: 8px;
}

.header__burger span:nth-child(2),
.header__burger span:nth-child(3) {
  top: 16px;
}

.header__burger span:nth-child(4) {
  top: 24px;
}

