@import "~common";

.intro {
  background-image: url('bg-decor-left.png'), url('bg-decor-right.png'), url('bg-grey.png');
  background-repeat: no-repeat;
  background-position: top left, bottom right, center;
  background-size: contain, contain, cover;

  @include media($desktop) {
    background-size: 40%, 40%, cover; }

  @include media($mobile-lg) {
    background-size: 50%, 50%, cover; }

  @include media($mobile-sm) {
    background-size: 60%, 60%, cover; }

  &__content {
    min-height: 500px;

    @include media($desktop) {
      min-height: 480px; }

    @include media($mobile-md) {
      min-height: 440px;
      padding-bottom: 30px; } }

  &__title {
    margin-bottom: 20px;
    padding: 105px 112px 0;
    text-align: center;

    @include media($desktop) {
      font-size: 38px; }

    @include media($tablet) {
      font-size: 33px;
      padding: 135px 100px 0; }

    @include media($mobile-lg) {
      padding: 135px 60px 0;
      font-size: 30px;
      line-height: 40px; }

    @include media($mobile-md) {
      padding: 135px 0 0;
      font-size: 26px;
      line-height: 35px; }

    @include media($mobile-xs) {
      padding-top: 95px;
      font-size: 25px; }

    span {
      color: $color-theme;
      font-weight: $font-weight-black; } }

  &__text {
    margin-bottom: 50px;
    font-size: 22px;
    text-align: center;

    @include media($mobile-lg) {
      margin-bottom: 40px;
      font-size: 18px; } }

  &__button {
    text-align: center;

    a {
      display: inline-block;
      padding: 14px 21px;
      background-color: $color-button;
      color: #fff;
      font-size: 16px;
      border: 2px solid transparent;
      text-decoration: none;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;

      @include media(400px) {
        width: 100%;
        font-size: 13px; }

      &:hover {
        background-color: #fff;
        border-color: $color-button;
        color: $color-button; } } } }
