@import "~common";

.varieties-more {
  flex-basis: 100%;
  padding: 46px 108px 52px 26px;
  margin-bottom: 28px;
  background-color: #e9e9e9;

  @include media($desktop-lg) {
    height: auto;
    padding-right: 26px; }

  @include media($tablet) {
    margin-bottom: 5px; }

  &__slider-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-left: 45px;

    @include media(1070px) {
      display: block;
      margin-left: 0;
      margin-bottom: 20px; } }

  &__slider {
    width: 292px;

    @include media(1070px) {
      margin: 0 auto; }

    @include media($mobile-sm) {
      width: 100%; }

    .slick-prev {
      left: -45px;
      background: url('left-arrow.png');

      &:hover, &:focus {
        background: url('left-arrow.png'); } }

    .slick-next {
      right: -45px;
      background: url('right-arrow.png');

      &:hover, &:focus {
        background: url('right-arrow.png'); } }

    .slick-arrow {
      width: 24px;
      height: 38px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &:before {
        content: none; } } }

  &__photo {
    width: 100%;
    height: 173px;

    @include media($mobile-sm) {
      height: 185px; }

    @include media($mobile-xs) {
      height: 150px; }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__content {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 415px);
    padding-left: 85px;

    @include media($desktop-lg) {
      width: calc(100% - 345px); }

    @include media(1070px) {
      display: block;
      width: 100%;
      padding-left: 0; } }

  &__title {
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: $font-bold;

    @include media($mobile-sm) {
      font-size: 20px; } }

  &__text {
    margin-bottom: 30px;
    font-size: 16px;

    @include media($mobile-sm) {
      font-size: 15px;
      line-height: 20px; } }

  &__questions {
    display: inline-block;
    vertical-align: middle;
    margin-right: 36px;
    padding: 12px 28px;
    color: #2d2d2d;
    font-size: 16px;
    font-weight: $font-medium;
    border: 2px solid #2d2d2d;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;

    @include media($mobile-md) {
      display: block;
      margin-right: 0;
      margin-bottom: 20px;
      text-align: center; }

    &:hover {
      background-color: #2d2d2d;
      color: #fff; } }

  &__costing {
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    color: #fff!important;
    background-color: $color-button;
    font-size: 16px;
    border: 2px solid transparent;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;

    @include media($mobile-md) {
      display: block;
      text-align: center; }

    &:hover {
      background-color: #fff;
      color: $color-button!important;
      border-color: $color-button; } } }
