.intro {
  background-image: url("./bg-decor-left.png"), url("./bg-decor-right.png"), url("./bg-grey.png");
  background-repeat: no-repeat;
  background-position: top left, bottom right, center;
  background-size: contain, contain, cover;
}

@media screen and (max-width: 1199px) {
  .intro {
    background-size: 40%, 40%, cover;
  }
}

@media screen and (max-width: 767px) {
  .intro {
    background-size: 50%, 50%, cover;
  }
}

@media screen and (max-width: 479px) {
  .intro {
    background-size: 60%, 60%, cover;
  }
}

.intro__content {
  min-height: 500px;
}

@media screen and (max-width: 1199px) {
  .intro__content {
    min-height: 480px;
  }
}

@media screen and (max-width: 575px) {
  .intro__content {
    min-height: 440px;
    padding-bottom: 30px;
  }
}

.intro__title {
  margin-bottom: 20px;
  padding: 105px 112px 0;
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .intro__title {
    font-size: 38px;
  }
}

@media screen and (max-width: 991px) {
  .intro__title {
    font-size: 33px;
    padding: 135px 100px 0;
  }
}

@media screen and (max-width: 767px) {
  .intro__title {
    padding: 135px 60px 0;
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (max-width: 575px) {
  .intro__title {
    padding: 135px 0 0;
    font-size: 26px;
    line-height: 35px;
  }
}

@media screen and (max-width: 360px) {
  .intro__title {
    padding-top: 95px;
    font-size: 25px;
  }
}

.intro__title span {
  color: #0086f0;
  font-weight: 900;
}

.intro__text {
  margin-bottom: 50px;
  font-size: 22px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .intro__text {
    margin-bottom: 40px;
    font-size: 18px;
  }
}

.intro__button {
  text-align: center;
}

.intro__button a {
  display: inline-block;
  padding: 14px 21px;
  background-color: #e31851;
  color: #fff;
  font-size: 16px;
  border: 2px solid transparent;
  text-decoration: none;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

@media screen and (max-width: 400px) {
  .intro__button a {
    width: 100%;
    font-size: 13px;
  }
}

.intro__button a:hover {
  background-color: #fff;
  border-color: #e31851;
  color: #e31851;
}

