@import "~common";

.calculator {
  margin-bottom: 80px;

  &__title {
    margin-top: 36px;
    margin-bottom: 40px;
    text-align: center; }

  &__body {
    display: flex;
    justify-content: flex-start;

    @include media($desktop) {
      flex-direction: column; } }

  &__left {
    flex-basis: 653px;
    padding: 35px 35px 48px;
    background-color: #f3f3f3;

    @include media($desktop) {
      flex-basis: auto;
      padding: 35px 95px 48px; }

    @include media($tablet) {
      padding: 35px 25px 48px; }

    @include media($mobile-sm) {
      padding: 35px 15px 48px; } }

  &__template {
    margin-bottom: 40px;

    @include media($mobile-sm) {
      margin-bottom: 25px; } }

  &__field {
    margin-bottom: 40px;

    @include media($mobile-sm) {
      margin-bottom: 25px; }

    &:last-child {
      margin-bottom: 0; }

    label {
      margin-right: 10px;
      cursor: pointer;

      @include media($mobile-sm) {
        margin: 0; } }

    .jq-checkbox {
      width: 19px;
      height: 19px;
      border-radius: 0;
      border: 1px solid #c6c6c6;
      background: #fff;

      &.checked {

        .jq-checkbox__div {
          width: 14px;
          height: 8px;
          margin: 2px 0 0 2px;
          border-color: $color-theme; } } }

    .jq-radio {
      width: 20px;
      height: 20px;
      background: #fff;

      &.checked {

        .jq-radio__div {
          width: 12px;
          height: 12px;
          background: $color-theme;
          box-shadow: none; } } }

    &--eyelet, &--mounting {
      display: inline-block;
      vertical-align: middle; }

    &--gluing {
      display: inline-block;
      vertical-align: middle;
      margin-right: 95px; }

    &--eyelet {
      margin-right: 98px;

      @include media($desktop) {
        margin-right: 10px; } }

    &--calculate {
      margin-top: 35px;

      button {
        display: block;
        width: 517px;
        margin: 0 auto;
        padding: 15px 0;
        color: #fff;
        background-color: $color-button;
        border: 2px solid transparent;
        text-transform: uppercase;
        cursor: pointer;
        transition: color 0.3s, background-color 0.3s, border-color 0.3s;

        @include media(700px) {
          width: 100%; }

        &:hover {
          color: $color-button;
          background-color: #fff;
          border-color: $color-button; }

        &[disabled] {
          opacity: 0.5;
          cursor: default;

          &:hover {
            color: #fff;
            background-color: $color-button;
            border-color: transparent; } } } }

    &--m {
      margin-right: 8px; }

    &--size {
      display: inline-block;
      vertical-align: middle;

      @include media($mobile-sm) {
        display: block; }

      input {
        width: 157px;
        padding: 12px 5px 12px 15px;
        font-family: $font_main;
        color: $color-text-base;
        font-size: 16px;
        border: none;

        @include media($tablet) {
          width: 130px; }

        @include media(700px) {
          width: 100px; }

        @include media($mobile-md) {
          width: 70px; }

        @include media($mobile-sm) {
          width: 100%;
          margin-top: 15px;
          font-size: 14px; } }

      .jq-selectbox {
        box-shadow: none;

        @include media(700px) {
          z-index: 5; }

        &.focused {

          .jq-selectbox__select {
            border: none;
            box-shadow: none; } } }

      .jq-selectbox__select {
        padding: 0 35px 0 5px;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: transparent;

        &:hover {
          background: transparent;
          box-shadow: none; } }

      .jq-selectbox__trigger {
        border: none; }

      .jq-selectbox__select-text {
        font-family: $font_main;
        color: $color-text-base;
        font-size: 18px; }

      .jq-selectbox__dropdown {
        left: 7px;
        width: 100%;
        margin-top: 5px;
        box-shadow: 0 5px 17px 1px rgba(0, 0, 0, 0.11);
        border: none;
        border-radius: 0;

        ul {
          padding: 10px 0;

          li {
            padding: 5px 12px;
            font-family: $font_main;
            color: $color-text-base;
            font-size: 16px;

            @include media($mobile-sm) {
              font-size: 14px; }

            &:hover {
              background-color: $color-theme;
              color: #fff; } } } } }

    &--material, &--resolution, &--type {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media(700px) {
        flex-direction: column;
        align-items: flex-start; }

      .jq-selectbox {
        width: 340px;

        @include media(700px) {
          width: 100%;
          margin-top: 15px;
          z-index: 5; }

        &.focused {

          .jq-selectbox__select {
            border: none; } }

        .jq-selectbox__select {
          padding: 5px 45px 5px 10px;
          border: none;
          box-shadow: none;
          background: #fff;
          border-radius: 0;
          box-sizing: content-box;

          &:hover {
            background: #fff; } }

        .jq-selectbox__trigger {
          border: none; }

        .jq-selectbox__select-text {
          font-family: $font_main;
          color: $color-text-base;
          font-size: 16px;

          @include media($mobile-sm) {
            font-size: 14px; } }

        .jq-selectbox__trigger-arrow {
          top: 20px; }

        .jq-selectbox__dropdown {
          bottom: auto!important;
          width: 100%;
          margin-top: 5px;
          box-shadow: 0 5px 17px 1px rgba(0, 0, 0, 0.11);
          border: none;
          border-radius: 0;

          @include media($mobile-md) {
            width: auto; }

          ul {
            padding: 15px 0;

            li {
              padding: 5px 28px;
              font-family: $font_main;
              color: $color-text-base;
              font-size: 16px;

              @include media($mobile-md) {
                padding: 5px;
                font-size: 15px; }

              @include media($mobile-sm) {
                font-size: 14px; }

              &:hover {
                background-color: $color-theme;
                color: #fff; } } } } } }

    &--luv {

      input {
        width: 110px;
        padding: 12px 5px 12px 15px;
        font-family: $font_main;
        color: #171717;
        font-size: 16px;
        border: none;

        @include media(700px) {
          width: 100px; }

        @include media($mobile-sm) {
          width: 94%;
          margin-top: 15px;
          z-index: 5; } }

      &.active {
        display: block; }

      span {
        margin-left: 5px; } } }

  &__step {
    display: none; }

  &__right {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    width: calc(100% - 653px);
    padding: 30px 50px 48px;

    @include media($desktop) {
      align-items: center;
      flex-direction: row;
      width: auto; }

    @include media($mobile-md) {
      padding: 30px 0 48px; }

    &.active {
      display: flex;

      @include media(1120px) {
        display: block; } }

    ul {

      @include media($desktop) {
        margin-bottom: 20px; }

      li {
        margin-bottom: 15px;
        padding-left: 38px;
        background-image: url('tick-red.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 22px;
        font-weight: $font-medium;

        &.hide {
          display: none; } } } }

  &__sum {
    max-width: 340px;
    text-align: center;

    @include media($desktop) {
      max-width: none; }

    @include media($mobile-sm) {
      margin-bottom: 15px; } }

  &__sum-title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: $font-medium; }

  &__sum-count {
    color: $color-theme;
    font-size: 50px;
    font-weight: $font-bold;

    @include media($mobile-sm) {
      font-size: 42px; }

    @include media($mobile-xs) {
      font-size: 37px; } }

  &__message {
    display: none;
    max-width: 340px;
    text-align: center;

    &.active {
      display: block; } }

  &__help {
    font-size: 11px;
    color: #6d6d6d; }

  &__in {
    height: 80px;
    padding-top: 35px;

    @include media(700px) {
      margin-bottom: 10px; }

    input {
      width: 100%;
      padding: 14px 10px 14px 35px;
      border: none;
      font-family: $font_main;
      font-size: 16px;
      color: #171717;
      outline: none;

      &::placeholder {
        color: #8b8b8b;
        font-size: 16px; } }

    button {
      width: 100%;
      padding: 12px 0;
      font-size: 16px;
      color: #fff;
      background-color: $color-button;
      border: 2px solid transparent;
      cursor: pointer;
      transition: border-color 0.3s, color 0.3s, background-color 0.3s;

      &:hover {
        border-color: $color-button;
        color: $color-button;
        background-color: #fff; } } }

  &__in-text {
    display: none;
    text-align: center;
    font-size: 17px;
    font-weight: $font-bold;
    color: $color-theme; }

  &__input-name {
    background-image: url('user-icon.png');
    background-repeat: no-repeat;
    background-position: left 12px center;
    background-size: 16px; }

  &__input-phone {
    background-image: url('phone-icon.png');
    background-repeat: no-repeat;
    background-position: left 12px center;
    background-size: 12px; }

  &__user {
    margin-bottom: 20px;
    padding: 20px 35px;
    background-color: #f3f3f3;

    @include media($mobile-sm) {
      padding: 20px 15px; }

    .calculator__in {
      height: auto;
      padding-top: 0; } }

  &__user-title {
    margin-bottom: 20px;
    color: $color-theme;
    text-align: center;
    font-weight: $font-medium; }

  &__user-content {
    display: flex;
    justify-content: space-between;
    max-width: 495px;
    margin: 0 auto;

    @include media(700px) {
      flex-direction: column; } }

  &__sum-t {
    display: none; }

  &__sum-text {
    display: none;
    font-size: 11px;
    color: $color-button;
    text-align: center; }

  &__gluing-side {
    display: none; } }

.calc {
  .calculator__user {
    display: none; } }
