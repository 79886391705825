@import "~common";

.footer {
  display: block;
  background-color: #3c3c3c;

  &__wrapper {
    padding: 80px 0 60px; }

  &__map {
    display: inline-block;
    vertical-align: top;
    margin-right: 100px;

    @include media($desktop) {
      display: block;
      margin-right: 0;
      margin-bottom: 20px; }

    iframe {
      width: 555px;
      height: 257px;

      @include media($desktop) {
        width: 100%;
        height: 350px; } } }

  &__contacts {
    display: inline-block;
    vertical-align: top;

    @include media($desktop) {
      display: block;
      text-align: center; }

    ul {

      @include media($desktop) {
        display: inline-block; }

      @include media($mobile-md) {
        text-align: left; } }

    li {
      margin-bottom: 18px; }

    a {
      padding: 5px 0 5px 28px;
      text-decoration: none;
      color: #fff;
      background-repeat: no-repeat;
      transition: color 0.3s;

      &:hover {
        color: $color-theme; } } }

  &__contacts-title {
    margin-bottom: 30px;
    font-weight: $font-medium;
    color: #fff; }

  &__contacts-phone {
    background-image: url('phone-icon.png');
    background-size: 14px;
    background-position: left 3px center; }

  &__contacts-email {
    background-image: url('email-icon.png');
    background-size: 20px;
    background-position: left center; }

  &__contacts-address {
    background-image: url('mark-icon.png');
    background-size: 14px;
    background-position: left 3px center; }

  &__times {
    padding-left: 28px;
    color: #fff;
    line-height: 25px;
    background-image: url('time-icon.png');
    background-size: 19px;
    background-repeat: no-repeat;
    background-position: left top; }

  &__copyright {
    padding: 23px 0;
    border-top: 1px solid #565656;
    color: #676767;
    font-size: 14px;
    text-align: center; } }
