.varieties {
  background-color: #fff;
}

.varieties__wrapper {
  padding: 97px 0 80px;
}

.varieties__wrapper .ui-widget.ui-widget-content {
  border: none;
  padding: 0;
}

.varieties__wrapper .ui-tabs .ui-tabs-nav {
  padding: 0;
  background: transparent;
  border: none;
}

.varieties__wrapper .ui-tabs .ui-tabs-nav li {
  flex-basis: 22.5%;
  margin: 0;
  margin-bottom: 28px;
  border: none;
  background: transparent;
}

@media screen and (max-width: 991px) {
  .varieties__wrapper .ui-tabs .ui-tabs-nav li {
    margin-bottom: 5px;
  }
}

.varieties__wrapper .ui-tabs .ui-tabs-nav li.ui-tabs-active {
  margin-top: 0;
  margin-bottom: 5px;
  padding: 0;
}

.varieties__wrapper .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
  width: 100%;
  padding: 0;
  outline: none;
}

.varieties__wrapper .ui-state-active a,
.varieties__wrapper .ui-state-active a:link,
.varieties__wrapper .ui-state-active a:visited {
  color: #171717;
}

.varieties__wrapper .ui-tabs .ui-tabs-panel {
  padding: 0;
}

.varieties__wrapper .ui-widget-content[aria-hidden="true"] {
  display: block !important;
  height: 0;
  overflow: hidden;
}

.varieties__wrapper .ui-widget-content[aria-hidden="false"] {
  display: block !important;
  height: auto;
}

.varieties__title {
  margin-bottom: 38px;
  text-align: center;
}

.varieties__list {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .varieties__list {
    flex-direction: column;
  }
}

.varieties__item {
  width: 100%;
  height: 182px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  overflow: hidden;
  cursor: pointer;
  transition: background-size 0.5s;
}

@media screen and (max-width: 991px) {
  .varieties__item {
    height: 60px;
  }
}

@media screen and (max-width: 991px) {
  .varieties__item:hover {
    background-size: 110%;
  }
}

.varieties__item:hover .varieties__button {
  margin-top: 2px;
}

@media screen and (max-width: 991px) {
  .varieties__item:hover .varieties__button {
    margin-top: 0;
  }
}

.varieties__item:hover .varieties__name {
  padding-top: 30px;
}

@media screen and (max-width: 991px) {
  .varieties__item:hover .varieties__name {
    padding-top: 17px;
  }
}

.varieties__item--decor1 {
  background-image: url("./decor1.png");
}

.varieties__item--decor2 {
  background-image: url("./decor2.png");
}

.varieties__item--decor3 {
  background-image: url("./decor3.png");
}

.varieties__item--decor4 {
  margin-right: 0;
  background-image: url("./decor4.png");
}

.varieties__item--decor5 {
  background-image: url("./decor5.png");
}

.varieties__item--decor6 {
  background-image: url("./decor6.png");
}

.varieties__item--decor7 {
  background-image: url("./decor7.png");
}

.varieties__item--decor8 {
  margin-right: 0;
  background-image: url("./decor8.png");
}

.varieties__name {
  height: calc(100% - 44px);
  padding-top: 55px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: padding-top 0.3s;
}

@media screen and (max-width: 991px) {
  .varieties__name {
    height: auto;
    padding: 0;
    padding-top: 17px;
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .varieties__name {
    font-size: 15px;
  }
}

@media screen and (max-width: 360px) {
  .varieties__name {
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .varieties__name br {
    display: none;
  }
}

.varieties__button {
  margin-top: 44px;
  padding: 13px 0;
  font-size: 16px;
  color: #fff;
  background-color: #e31851;
  text-align: center;
  transition: margin-top 0.3s;
}

@media screen and (max-width: 991px) {
  .varieties__button {
    display: none;
  }
}

.varieties__banner {
  background-image: url("./montazh.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.varieties__banner-title {
  padding: 70px 20px;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

@media screen and (max-width: 575px) {
  .varieties__banner-title {
    padding: 40px 20px;
    font-size: 25px;
  }
}

@media screen and (max-width: 479px) {
  .varieties__banner-title {
    padding: 60px 10px;
    font-size: 22px;
    line-height: 30px;
  }
}

.varieties__banner-title span {
  color: #e31851;
  font-weight: 900;
}

