@import "~common";

.table {
  border: 1px solid #d3d3d3;
  font-family: $font_main;

  &--widescreen {

    .table__col--big {
      width: 51.4%;
      padding: 12px 30px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 54%; }

      @include media($mobile-lg) {
        padding: 10px; }

      @include media($mobile-sm) {
        padding: 5px; } }

    .table__col--middle {
      width: 23.1%;
      padding: 12px 34px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 23%; }

      @include media($mobile-lg) {
        padding: 10px; }

      @include media($mobile-sm) {
        padding: 5px; } }

    .table__col--small {
      width: 25.5%;
      padding: 12px 34px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 23%; }

      @include media($mobile-lg) {
        padding: 10px; }

      @include media($mobile-sm) {
        padding: 5px; } } }

  &--interior {

    .table__col--big {
      width: 60%;
      padding: 12px 30px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 70%; }

      @include media($mobile-sm) {
        padding: 5px; } }

    .table__col--middle {
      width: 40%;
      padding: 12px 34px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 30%; }

      @include media($mobile-sm) {
        padding: 5px; } } }

  &--services {

    .table__col--big {
      width: 60%;
      padding: 12px 30px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 70%; }

      @include media($mobile-sm) {
        padding: 5px; } }

    .table__col--middle {
      width: 40%;
      padding: 12px 34px;

      @include media($tablet) {
        padding: 10px 15px; }

      @include media($mobile-lg) {
        width: 30%; }

      @include media($mobile-sm) {
        padding: 5px; } } }

  &__header {
    background-color: #f3f3f3;

    .table__col {
      font-size: 20px;
      font-weight: $font-medium;
      padding-top: 14px;
      padding-bottom: 14px;

      @include media($tablet) {
        font-size: 16px; }

      @include media($mobile-md) {
        font-size: 15px; }

      @include media($mobile-sm) {
        font-size: 11px; }

      @include media($mobile-xs) {
        font-size: 10px; } } }

  &__row {
    border-bottom: 1px solid #d3d3d3;
    @include clearfix;

    @include media($mobile-lg) {
      display: flex; }

    &:last-child {
      border-bottom: none; } }

  &__col {
    float: left;
    border-right: 1px solid #d3d3d3;

    @include media($tablet) {
      font-size: 15px; }

    @include media($mobile-lg) {
      float: none; }

    @include media($mobile-sm) {
      font-size: 13px; }

    @include media($mobile-xs) {
      font-size: 12px; }

    &:last-child {
      border-right: none; } } }
